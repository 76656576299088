import { graphql } from "gatsby";
import Seo from "../components/layout/seo";
import IndexPage from "../components/pages";
import React from "react";

// export const query = graphql`
//   query homeQuery {
//     storyblokEntry(full_slug: { eq: "home" }) {
//       content
//       name
//     }
//   }
// `;

/* export const query = graphql`
  query homeQuery {
    allStoryblokEntry(filter: { lang: { eq: "default" } }) {
      nodes {
        name
        slug
        content
        field_component
      }
    }
  }
`; */

export default ({ location }) => (
  <>
    <Seo
      title="Attrezzi per Lavorare Legno ed Alluminio"
      desc="International Saws è il marchio italiano specializzato in lame circolari, metallo duro e utensili per la lavorazione del legno, della plastica e dell'alluminio."
    />
    <IndexPage location={location} />
  </>
);
